/** @format */

import { reactive, toRefs } from '@vue/composition-api'
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

const state = reactive({
	startupNavbarData: [
		{ linkText: 'Set initial outstanding values', routeName: 'Startup' },
		{
			linkText: 'Index dispatches, purchase invoices',
			routeName: 'SetInventoryVariables',
		},
		{
			linkText: 'Get the latest inventory report',
			routeName: 'InventoryReportDetailed',
		},
		{
			linkText: 'Set credit debit values from inventory',
			routeName: 'SetCreditDebitValuesFromInventory',
		},
		{
			linkText: 'Set outstanding values from previous year',
			routeName: 'SetOutstandingValuesFromInventory',
		},
	],
	accountantNavbarData: [
		{
			linkText: 'View Sale Invoices',
			routeName: 'ViewSaleInvoices',
		},
	],
	utilitiesNavbarData: [
		{ linkText: 'Search Document', routeName: 'SearchDocument' },
		{
			linkText: 'Customer Route List',
			routeName: 'CustomerRouteList',
		},
		{
			linkText: 'Customer Consumption',
			routeName: 'TimeIntervalCustomerConsumption',
		},
		{
			linkText: 'Customer History',
			routeName: 'CustomerHistory',
		},
		{
			linkText: 'Price List',
			routeName: 'PriceList',
		},
		{
			linkText: "Customer's Own Price List",
			routeName: 'CustomerPriceList',
		},
	],
	stockNavbarData: [
		{
			linkText: 'Add/Edit Stock Item',
			routeName: 'AddEditStockItem',
		},
		{
			linkText: 'Stock Dashboard',
			routeName: 'Stock',
		},
		{
			linkText: 'Insufficient Stock Items',
			routeName: 'InsufficientStock',
		},
	],
	customerNavbarData: [
		{
			linkText: 'Customer List',
			routeName: 'CustomerList',
		},
		{
			linkText: 'Customer Unpaid Invoices',
			routeName: 'GetUnpaidInvoices',
		},
		{
			linkText: 'Customer Payments',
			routeName: 'CustomerPayments',
		},
		{
			linkText: "Add Customer's Special Prices",
			routeName: 'CustomerSpecialPrices',
		},
	],
	companyNavbarData: [{ linkText: 'Add Company', routeName: 'CompanyAddEdit' }],
	orderNavbarData: [
		{ linkText: 'Add Order', routeName: 'AddOrder' },
		{ linkText: 'Edit/View Order', routeName: 'ViewOrder' },
	],
	dispatchNavbarData: [
		{ linkText: 'Create Dispatch', routeName: 'ViewOrdersToDispatch' },
		{
			linkText: 'Create Return Order Dispatch',
			routeName: 'CreateReturnOrderDispatch',
		},
		{ linkText: 'Edit/View Dispatch', routeName: 'ViewDispatch' },
	],
	deliveryNavbarData: [
		{ linkText: 'Create New Route List', routeName: 'CreateNewRouteList' },
		{
			linkText: 'Add Extract Dispatch to List',
			routeName: 'AddExtractDispatchToRouteList',
		},
		{ linkText: 'Add Edit Driver', routeName: 'AddEditDriver' },
		{ linkText: 'Add Edit Vehicle', routeName: 'AddEditVehicle' },
	],
	invoiceNavbarData: [
		{
			linkText: 'Add or Edit/View Invoice',
			routeName: '/sale-invoice/add-new-sale-invoice',
		},
	],
	// *****
	salesInvoiceNavbarData: [
		{
			linkText: 'Sales Invoice',
			routeName: 'ViewDispatchesToInvoice',
		},
		{
			linkText: 'Edit/View Sales Invoice',
			routeName: 'ViewInvoices',
		},
		{
			linkText: 'Create Credit Invoice',
			routeName: 'CreateCreditInvoiceDispatchOrder',
		},
	],
	purchaseInvoiceNavbarData: [
		{
			linkText: 'Add Purchase Invoice',
			routeName: 'AddPurchaseInvoice',
		},
		{
			linkText: 'Edit/View Purchase Invoice',
			routeName: 'EditPurchaseInvoice',
		},
		{
			linkText: 'Purchase Invoice Report',
			routeName: 'PurchaseInvoiceReport',
		},
	],
	reminderNavbarData: [
		{
			linkText: 'Send Reminder',
			routeName: 'SendReminder',
		},
		{
			linkText: 'View Reminder',
			routeName: 'ViewReminder',
		},
		{
			linkText: 'Send to Inkasso',
			routeName: 'SendToInkasso',
		},
		{
			linkText: 'Send to Kronofogden',
			routeName: 'SendToKronofogden',
		},
		{
			linkText: 'Doubtful Receivables',
			routeName: 'SendToDoubtfulReceivables',
		},
	],
	transactionNavbarData: [
		{ linkText: 'Collect/Set off Payment', routeName: 'AddTransaction' },
		{
			linkText: 'Get Transactions',
			routeName: 'GetDataCollectPayment',
		},
		{
			linkText: 'Payments To Suppliers',
			routeName: 'PaymentsToSuppliers',
		},
	],
	accountingNavbarData: [
		{
			linkText: 'Book Keep Collect Payments',
			routeName: 'Accounting',
		},
		{
			linkText: 'Create Collected Payments Report',
			routeName: 'CreateCollectedPaymentsReport',
		},
		{
			linkText: 'Get Bookkeeping Report List',
			routeName: 'BookkeepingReportList',
		},
		{
			linkText: 'Invoice Journal',
			routeName: 'CreateInvoiceJournal',
		},
		{
			linkText: 'Get Out Land Invoices',
			routeName: 'OutLandInvoices',
		},
	],
	adminNavbarData: [
		{
			linkText: 'Create Order',
			routeName: 'CreateOrder',
		},
		{
			linkText: 'Create Dispatch-Order',
			routeName: 'CreateOrderDispatch',
		},
		{
			linkText: 'Create Invoice-Dispatch-Order',
			routeName: 'CreateOrderDispatchInvoice',
		},
		{
			linkText: 'Time Interval Revenue Values',
			routeName: 'TimeIntervalRevenue',
		},
		{
			linkText: 'Incomes By Route Id',
			routeName: 'IncomesByRouteId',
		},
		{
			linkText: 'Invoices to be paid by customers',
			routeName: 'InvoicesToBePaid',
		},
		{
			linkText: 'Change Invoice Due Date',
			routeName: 'ChangeInvoiceDueDate',
		},
		{
			linkText: 'Change Reminder Due Date',
			routeName: 'ChangeReminderDueDate',
		},
		{
			linkText: 'Startup',
			routeName: 'Startup',
		},
	],
	devNavbarData: [
		{
			linkText: 'Collections from Customers',
			routeName: 'IncomingTransactions',
		},
	],
	marketingNavbarData: [
		{
			linkText: 'Add New Potential Customer',
			routeName: 'Marketing',
		},
		{
			linkText: 'Potential Customer Route List',
			routeName: 'PotentialCustomerRouteList',
		},
		{
			linkText: 'Daily Visit List',
			routeName: 'DailyVisitsList',
		},
		{
			linkText: 'Daily Alarm List',
			routeName: 'DailyAlarmList',
		},
		{
			linkText: 'Add Customer Info',
			routeName: 'AddCustomerInfo',
		},
		{
			linkText: 'Get Customer Marketing Info',
			routeName: 'GetCustomerMarketingInfo',
		},
		{
			linkText: 'Get Customer Economic Info',
			routeName: 'GetCustomerEconomicInfo',
		},
		// --------------------------------------
		{
			linkText: 'Time Interval Sales Statistics',
			routeName: 'GetTimeIntervalCustomersInvoicesStatistics',
		},
		{
			linkText: 'Customer Purchases Statistics',
			routeName: 'GetCountDispatchesByRevenueCenters',
		},
	],
})
export default function useSearchBoxState() {
	return { ...toRefs(state) }
}
